import $ from 'jquery';
import axios from 'axios';
import MapBox from './mapbox';

export default class Country {
    constructor () {
        this.initEvents();
    }

    initEvents () {
        this.onSubmitForm();
        this.onClickRandomButton();
    }

    // events
    onSubmitForm() {
        $('form').on('submit', () => {
            let search_country = $('input').val();

            this.getDataCountryFromApi(search_country)
            .then((response) => {
                let data = response.data[0];
                this.getData(data);
                MapBox.createMap().reloadMap();
            })
            .catch((error) => {
                console.log(error);
            });

            $('input').val('');

            return false;
        });
    }

    onClickRandomButton() {
        $('img.random').on('click', () => {
            this.getRandomCountry().then(() => {
                MapBox.createMap().reloadMap();
            }).catch((error) => {
                console.log(error);
            });
        });
    }
    // --

    // requests from API
    getDataCountryFromApi(country) {
        return axios.get(`https://restcountries.com/v3.1/name/${country}`);
    }

    getDataRandomCountryFromApi(getRandomId, response) {
        return axios.get('https://restcountries.com/v3.1/all');
    }
    // --

    // random country
    async getRandomCountry() {
        let getRandomId = Math.floor(Math.random() * 251);
        const response = await this.getDataRandomCountryFromApi();
        let data = response.data[getRandomId];
        this.getData(data);
    }

    getData(data) {
        let country = data.name.common;
        let coords = {};
        coords.lat = data.latlng[0];
        coords.long = data.latlng[1];
        coords = JSON.stringify(coords);
        this.setCoordsInSession(coords);
        this.fillViewWithData(data);
    }
    // --

    setCoordsInSession(coords) {
        sessionStorage.setItem('coords', coords);
    }
    // --

    // fill with data
    fillViewWithData(data) {
        let languages = [];

        for (const key in data.languages) {
            languages.push(data.languages[key]);
        }

        languages = languages.join(', ');

        let name = data.name.common || 'no';
        let nativeName = Object.values(data.name.nativeName)[0].official || 'no';
        let topLevelDomain = data.tld[0] || 'no';
        let capital = data.capital[0] || 'no';
        let continent = data.region || 'no';
        let population = data.population || 'no';
        population = new Intl.NumberFormat('fr-FR').format(population);
        let demonym = Object.values(data.demonyms)[0].m + ' / ' + Object.values(data.demonyms)[0].f || 'no';
        let currency_name = Object.values(data.currencies)[0].name || 'no';
        let currency_symbol = Object.values(data.currencies)[0].symbol || 'no';
        let flag = data.flag;

        $('.name').text(name);
        $('.nativeName').text(nativeName);
        $('.topLevelDomain').text(topLevelDomain);
        $('.capital').text(capital);
        $('.continent').text(continent);
        $('.population').text(population);
        $('.demonym').text(demonym);
        $('.currency_name').text(currency_name);
        $('.currency_symbol').text(currency_symbol);
        $('.languages').text(languages);
        $('.flag').text(flag);
        $('main > div.wrap').css('opacity', '1');
        $('body').css('opacity', '1');
    }
    // --
}
