import $ from 'jquery';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';

export default class MapBox {
	constructor () {
		this.initElements();
		this.initEvents();
	}

	static mapInstance = null;

	static createMap() {
		if (MapBox.mapInstance === null) {
			MapBox.mapInstance = new MapBox();
		}

		return MapBox.mapInstance;
	}

	initElements () {
		this.API_KEY = 'pk.eyJ1IjoianVsaWV0dGUtYm9pcyIsImEiOiJjbHcwb2Q1a2YwM3NjMmlwaHdyZWUzcmluIn0.Q5M4NbH8-4INUgW5gUIa8A';
		this.coords = this.getCoordsFromSession();
	}

	initEvents () {
		this.initMap();
		this.onMapLoaded();
		$('body').css('opacity', '1');
	}

	// events
	onMapLoaded() {
		this.map.on('load', () => {
			let coords = this.getCoordsFromSession();
			coords = JSON.parse(coords);

			this.pulsingDot = this.createMarked();
			this.map.addImage('pulsing-dot', this.pulsingDot, { pixelRatio: 2 });
			this.map.addSource('point', {
				"type": "geojson",
				"data": {
					"type": "FeatureCollection",
					"features": [{
						"type": "Feature",
						"geometry": {
							"type": "Point",
							"coordinates": [coords.long, coords.lat]
						}
					}]
				}
			});
			this.map.addLayer({
				"id": "points",
				"type": "symbol",
				"source": 'point',
				"layout": {
					"icon-image": "pulsing-dot"
				}
			});
		});
	}
	// --

	reloadMap() {
		let coords = this.getCoordsFromSession();
		coords = JSON.parse(coords);

		this.map.getSource("point").setData({
			"type": "FeatureCollection",
			"features": [{
				"type": "Feature",
				"geometry": {
					"type": "Point",
					"coordinates": [coords.long, coords.lat]
				}
			}]
		});

		this.map.flyTo({center: [coords.long, coords.lat]});
	}

	initMap() {
		let coords = this.getCoordsFromSession();
		coords = JSON.parse(coords);

		mapboxgl.accessToken = this.API_KEY;
		this.map = new mapboxgl.Map({
			container: 'map',
			style: 'mapbox://styles/mapbox/dark-v10',
			zoom: 2,
			center: [coords.long, coords.lat]
		});
	}

	getCoordsFromSession() {
		return sessionStorage.getItem('coords');
	}

	createMarked() {
		let size = 150;
		let classContext = this;
		return {
			width: size,
			height: size,
			data: new Uint8Array(size * size * 4),

			// get rendering context for the map canvas when layer is added to the map
			onAdd(){
				let canvas = document.createElement('canvas');
				canvas.width = this.width;
				canvas.height = this.height;
				this.context = canvas.getContext('2d');
			},

			// called once before every frame where the icon will be used
			render(){
				let duration = 1500;
				let t = (performance.now() % duration) / duration;

				let radius = size / 2 * 0.3;
				let outerRadius = size / 2 * 0.7 * t + radius;
				let context = this.context;

				// draw outer circle
				context.clearRect(0, 0, this.width, this.height);
				context.beginPath();
				context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
				context.fillStyle = 'rgba(139, 211, 221,' + (1 - t) + ')';
				context.fill();

				// draw inner circle
				context.beginPath();
				context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
				context.fillStyle = 'rgba(139, 211, 221, 1)';
				context.strokeStyle = 'white';
				context.lineWidth = 2 + 4 * (1 - t);
				context.fill();
				context.stroke();

				// update this image's data with data from the canvas
				this.data = context.getImageData(0, 0, this.width, this.height).data;

				// continuously repaint the map, resulting in the smooth animation of the dot
				classContext.map.triggerRepaint();

				// return `true` to let the map know that the image was updated
				return true;
			}
		}
	}
}
