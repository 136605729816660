// Importation des dépendances
import $ from 'jquery';
import Country from './classes/country';
import MapBox from './classes/mapbox';

class App {
    constructor() {
        this.initApp();
    }

    initApp() {
        $(document).ready(() => {
            // On initialise les modules
            const country = new Country();
            country.getRandomCountry().then(() => {
                MapBox.createMap();
            }).catch((error) => {
                console.log(error);
            });
        });
    }
}

new App();


